import { Header, Message } from 'semantic-ui-react';
import type { Callback } from 'ts/base/Callback';
import {
	createGitTagPreFilterDropdownOptions,
	GitTagPreFilterDropdown
} from 'ts/commons/time/components/git_tags/GitTagPreFilterDropdown';

type GitTagProjectDropdownProps = {
	projects: string[] | null;
	selectedProject: string | null;
	setSelectedProject: Callback<string | null>;
};

/** Dropdown for selecting the project to fetch git tags for. */
export function GitTagProjectDropdown({ projects, selectedProject, setSelectedProject }: GitTagProjectDropdownProps) {
	return (
		<>
			<Header size="tiny" content="Project:" />
			{projects ? (
				<GitTagPreFilterDropdown<string>
					testId="git-tag-project-select"
					selectedValue={selectedProject}
					setSelectedValue={setSelectedProject}
					displayObjects={projects}
					optionMapper={createGitTagPreFilterDropdownOptions}
				/>
			) : (
				<Message>No projects available.</Message>
			)}
		</>
	);
}
