import { keepPreviousData, type UseQueryResult } from '@tanstack/react-query';
import { QUERY } from 'api/Query';
import type { UnresolvedCommitDescriptor } from 'custom-types/UnresolvedCommitDescriptor';
import { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Button, Form } from 'semantic-ui-react';
import type { FileListQuery } from 'ts/commons/dialog/PathEntitySelectionModalContent';
import {
	FILE_LIST_QUERY_EMPTY,
	FILE_LIST_QUERY_ERROR,
	FILE_LIST_QUERY_LOADING,
	fileListQueryData,
	PathEntitySelectionModalContent,
	useProjectIds
} from 'ts/commons/dialog/PathEntitySelectionModalContent';
import {
	buildAndSortEntries,
	getPathEntryForProjects,
	insertPathPrefix
} from 'ts/commons/dialog/PathEntitySelectionUtils';
import { openModal } from 'ts/commons/modal/ModalUtils';
import { NavigationHash } from 'ts/commons/NavigationHash';
import { ProjectAndUniformPath } from 'ts/commons/ProjectAndUniformPath';
import { StringUtils } from 'ts/commons/StringUtils';
import { UniformPath } from 'ts/commons/UniformPath';
import { EResourceType, type EResourceTypeEntry } from 'typedefs/EResourceType';
import { EType } from 'typedefs/EType';

type ProjectAndPathSelectionInputFieldProps = {
	/** The name of the project name in the form values */
	projectNameField: string;
	/** The name of the uniform path in the form values */
	uniformPathField: string;
	/** Input field label */
	label: string;
};

/** Provides an input field to select project and path. It must be wrapped around a form provider. */
export function ProjectAndPathSelectionInputField({
	projectNameField,
	uniformPathField,
	label
}: ProjectAndPathSelectionInputFieldProps) {
	const { control, setValue } = useFormContext();
	const formValues = useWatch({ control });
	const [projectAndPathValue, setProjectAndPathValue] = useState(
		ProjectAndUniformPath.of(formValues[projectNameField], formValues[uniformPathField])
	);

	return (
		<Form.Input
			data-testid="project-and-path-field"
			label={label}
			readOnly
			action={
				<Button
					type="button"
					data-testid="path-picker-button"
					icon="ellipsis horizontal"
					onClick={() =>
						showProjectAndPathSelectionModal({
							initialProject: formValues[projectNameField],
							initialPath: new UniformPath(formValues[uniformPathField]),
							onSave: (project: string, path: UniformPath) => {
								setValue(projectNameField, project);
								setValue(uniformPathField, path.getPath().toString());
								setProjectAndPathValue(ProjectAndUniformPath.of(project, path.getPath()));
							}
						})
					}
				/>
			}
			value={projectAndPathValue}
		/>
	);
}

type ProjectAndPathSelectionModalOptions = {
	onSave: (project: string, path: UniformPath, commit: UnresolvedCommitDescriptor) => void;
	initialProject?: string;
	initialPath?: UniformPath;
	initialCommit?: UnresolvedCommitDescriptor;
	forbiddenUniformPathTypes?: EType[];
	disableBranchAndTimeSelection?: boolean;
};

/** Renders the project and path selection modal. */
export function showProjectAndPathSelectionModal({
	onSave,
	initialProject = NavigationHash.getProject(),
	initialPath,
	initialCommit,
	disableBranchAndTimeSelection
}: ProjectAndPathSelectionModalOptions) {
	openModal({
		size: 1200,
		title: 'Select Project and Path',
		'data-testid': 'path-selection-modal',
		contentRenderer: close => (
			<PathEntitySelectionModalContent
				projectsSelectable
				initialProject={initialProject}
				initialPath={initialPath}
				initialCommit={initialCommit}
				onSave={(project: string | undefined, path: UniformPath, commit: UnresolvedCommitDescriptor) => {
					onSave(project!, path, commit);
				}}
				onClose={close}
				useEntries={useProjectAndPathData}
				disableCommitSelector={disableBranchAndTimeSelection}
			/>
		)
	});
}

function useProjectAndPathData(
	project: string,
	path: UniformPath,
	selectedCommit: UnresolvedCommitDescriptor,
	enabled = true
): FileListQuery {
	const projects = useProjectIds();
	const showProjects = StringUtils.isEmptyOrWhitespace(project) && path.isEmpty();

	// Empty project should not be invalid, for example when selecting a project
	const invalidProject = !StringUtils.isEmptyOrWhitespace(project) && !projects?.includes(project);

	// File list (in contrast to project list) should only be fetched according to the following conditions
	const doFetchFileList = enabled && !showProjects && !invalidProject;
	const pathResourceType = useResourceType(project, path, selectedCommit, doFetchFileList);
	const containers = useContainers(project, path, selectedCommit, doFetchFileList);

	if (!enabled) {
		return FILE_LIST_QUERY_EMPTY;
	}

	if (pathResourceType.isLoading) {
		return FILE_LIST_QUERY_LOADING;
	}

	const invalidPath = pathResourceType.data === EResourceType.UNKNOWN.toString();
	// Projects need to be checked here again in case the current project is empty
	if (!projects || invalidProject || invalidPath || (!showProjects && !pathResourceType.data)) {
		return FILE_LIST_QUERY_ERROR;
	}

	if (showProjects) {
		return fileListQueryData(getPathEntryForProjects(projects).filter(entry => entry.name.startsWith(project)));
	}
	return containers;
}

/** Fetches the info for the file list entries. Returns null if the path is invalid */
export function useContainers(
	project: string,
	path: UniformPath,
	commit: UnresolvedCommitDescriptor,
	enabled = true
): FileListQuery {
	const pathResourceType = useResourceType(project, path, commit, enabled);
	const childResourceTypes = useChildResourceTypes(project, path, commit, enabled);
	const displayMetrics = useDisplayMetrics(project, path, commit, enabled);

	if (!enabled) {
		return FILE_LIST_QUERY_EMPTY;
	}
	if (pathResourceType.isLoading) {
		return FILE_LIST_QUERY_LOADING;
	}

	if (pathResourceType.data == null || pathResourceType.data === EResourceType.UNKNOWN.name || !childResourceTypes) {
		return FILE_LIST_QUERY_ERROR;
	}

	const entries = buildAndSortEntries(project, childResourceTypes);
	displayMetrics.forEach(path => insertPathPrefix(project, path, entries));

	return fileListQueryData(entries);
}

function useChildResourceTypes(
	project: string,
	path: UniformPath,
	commit: UnresolvedCommitDescriptor,
	enabled = true
): Map<string, EResourceType> | null {
	const resourceTypesWithChildrenQuery = useResourceTypesOfChildren(project, path, commit, enabled);

	if (resourceTypesWithChildrenQuery.isPending || resourceTypesWithChildrenQuery.isError) {
		return null;
	}

	const mapEntries = (obj: Record<string, EResourceTypeEntry>): Array<[string, EResourceType]> => {
		return Object.keys(obj).map(k => [k, EResourceType[obj[k]!]]);
	};
	return new Map(mapEntries(resourceTypesWithChildrenQuery.data));
}

/**
 * Fetches the resource type for the given uniform path.
 *
 * The given path can be invalid, then the resource type just will be UNKNOWN, however the project must exist, otherwise
 * this causes a 404 error.
 */
function useResourceType(
	project: string,
	path: UniformPath,
	commit: UnresolvedCommitDescriptor,
	enabled = true
): UseQueryResult<EResourceTypeEntry | null> {
	return QUERY.getResourceType(project, {
		t: commit,
		'uniform-path': path.getPath(),
		'check-default-branch': true
	}).useQuery({
		enabled: enabled && !StringUtils.isEmptyOrWhitespace(project),
		placeholderData: keepPreviousData,
		throwOnError: false
	});
}

/**
 * Fetches the resource types of the children for the given uniform path.
 *
 * The given path can be invalid, then the resource type for the children will just be empty, however the project must
 * exist, otherwise this causes a 404 error.
 *
 * This is an extra method, even though the difference to {@see useResourceType} is only a single boolean, because the
 * return type of the used teamscale client method returns a completely different type.
 */
function useResourceTypesOfChildren(
	project: string,
	path: UniformPath,
	commit: UnresolvedCommitDescriptor,
	enabled: boolean
): UseQueryResult<Record<string, EResourceTypeEntry>> {
	return QUERY.getChildrenResourceTypes(project, {
		t: commit,
		'uniform-path': path.getPath()
	}).useQuery({
		enabled: enabled && !StringUtils.isEmptyOrWhitespace(project),
		placeholderData: keepPreviousData,
		throwOnError: false
	});
}

function useDisplayMetrics(
	project: string,
	path: UniformPath,
	commit: UnresolvedCommitDescriptor,
	enabled: boolean
): string[] {
	return [
		EType.NON_CODE.prefix,
		EType.ISSUE_QUERY.prefix,
		EType.TEST_IMPLEMENTATION.prefix,
		EType.TEST_EXECUTION.prefix,
		EType.TEST_QUERY.prefix,
		EType.SPEC_ITEM_QUERY.prefix
	].flatMap(constant => {
		let usePath = new UniformPath('');
		if (enabled && !StringUtils.isEmptyOrWhitespace(project)) {
			usePath = new UniformPath(constant);
		}

		// eslint-disable-next-line react-hooks/rules-of-hooks
		return useResourceType(project, usePath, commit, enabled).data === EResourceType.CONTAINER.name &&
			path.isProjectRoot()
			? [constant]
			: [];
	});
}
