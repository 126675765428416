import { Icon } from 'semantic-ui-react';
import { ArrayUtils } from 'ts/commons/ArrayUtils';
import type { FileListEntry } from 'ts/commons/dialog/PathEntitySelectionModalContent';
import { UniformPath } from 'ts/commons/UniformPath';
import { EResourceType } from 'typedefs/EResourceType';

const CONTAINER_PREFIX = 'container:';

/**
 * @param hash Of a container info child.
 * @returns The type for the container info child with the given hash
 */
export function getType(hash: string): EResourceType {
	if (hash.startsWith(CONTAINER_PREFIX)) {
		return EResourceType.CONTAINER;
	}
	return EResourceType.FILE;
}

/**
 * Constructs child entry objects that contain all information for rendering the child with deep links, proper sorting
 * and proper icons.
 *
 * @param childResourceTypes The types of all child resources
 */
export function buildAndSortEntries(project: string, childResourceTypes: Map<string, EResourceType>): FileListEntry[] {
	const entries = [...childResourceTypes.keys()].map(path =>
		buildEntry(project, path, childResourceTypes.get(path)!)
	);
	return sortEntries(entries);
}

/**
 * Sorts the given entries: Containers first, files last in ascending.
 *
 * @returns The sorted entries.
 */
function sortEntries(entries: FileListEntry[]): FileListEntry[] {
	const files = entries.filter(entry => !entry.isContainer);
	const container = entries.filter(entry => entry.isContainer);
	ArrayUtils.sortBy(container, 'name');
	ArrayUtils.sortBy(files, 'name');
	return [...container, ...files];
}

/**
 * Constructs a child entry object for a child path.
 *
 * @param project The project
 * @param pathString The path
 * @param resourceType The resource type of the path (file, directory or unknown)
 */
function buildEntry(project: string | undefined, pathString: string, resourceType: EResourceType): FileListEntry {
	const path = new UniformPath(pathString);

	const result = {
		name: path.getBasename() || project,
		project,
		path,
		isContainer: resourceType === EResourceType.CONTAINER,
		icon: <i /> // Needed such that the result has the same structure as the ones with the icons
	} as FileListEntry;

	if (result.isContainer) {
		result.icon = <Icon name="folder" />;
		result.name += '/';

		if (!(path.isEmpty() || pathString.endsWith('/'))) {
			result.path = new UniformPath(pathString + '/');
		}
	} else if (!path.isProjectRoot()) {
		result.icon = <Icon name="file outline" />;
	}
	return result;
}

/**
 * @param projects List of projects
 * @returns The list of projects in the file list.
 */
export function getPathEntryForProjects(projects: string[]): FileListEntry[] {
	return projects.map(project => buildEntry(project, '', EResourceType.FILE));
}

export function insertPathPrefix(project: string, path: string, entries: FileListEntry[]): void {
	entries.unshift({
		name: path,
		project,
		path: new UniformPath(path),
		isContainer: true,
		icon: <Icon name="settings" />
	});
}
