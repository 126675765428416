import PropTypes from 'prop-types';
import type { ComponentType, ForwardedRef } from 'react';
import { forwardRef, type JSX, useEffect, useRef, useState } from 'react';
import type {
	DropdownDividerProps,
	DropdownHeaderProps,
	DropdownItemProps,
	IconProps,
	SemanticShorthandItem,
	StrictDropdownItemProps,
	StrictDropdownProps
} from 'semantic-ui-react';
import { Dropdown, DropdownItem, Icon, Input } from 'semantic-ui-react';
import type { Callback } from 'ts/base/Callback';
import type { TeamscaleLink } from 'ts/base/routing/TeamscaleLink';
import type { ExtendTypeWith } from 'ts/commons/ExtendTypeWith';

/** Props for ValueAwareComponentType. */
export type ValueAwareComponentProps = { 'data-value': string };

/** A component that can accept a data-value prop which will be set to the value or text of the item. */
export type ValueAwareComponentType = ComponentType<ValueAwareComponentProps>;

/** Maps the possible dropdown item types to the props that are allowed for this type. */
type KindMap = {
	item: DropdownItemProps;
	header: DropdownHeaderProps;
	divider: DropdownDividerProps;
};

/**
 * The items that should be rendered into the SearchableDropdown. The options consist of an optional kind field (item,
 * header, divider) and the props that should be passed to the component.
 */
export type DropdownItemOptions =
	| {
			[K in keyof KindMap]: KindMap[K] & {
				kind: K;
				as?: ValueAwareComponentType | typeof TeamscaleLink;
			};
	  }[keyof KindMap]
	| ExtendTypeWith<DropdownItemProps, { as?: ValueAwareComponentType | typeof TeamscaleLink }>;

/** Appends the header and items to the given item options in case the items are not empty. */
export function addDropdownItemsWithHeader(
	itemOptions: DropdownItemOptions[],
	header: string,
	items: DropdownItemOptions[],
	headerIcon?: SemanticShorthandItem<IconProps>
): void {
	if (items.length === 0) {
		return;
	}
	itemOptions.push({
		kind: 'header',
		content: header,
		key: 'header-' + header,
		icon: headerIcon
	});
	itemOptions.push({
		kind: 'divider',
		key: 'divider-' + header
	});
	itemOptions.push(...items);
}

/** Props for SearchableDropdown. */
type InMenuSearchableDropdownProps = ExtendTypeWith<
	Omit<StrictDropdownProps, 'onOpen' | 'onClose'>,
	{
		id?: string;
		value?: string;
		onEnterDown?: Callback<string>;
		items: DropdownItemOptions[];
		filterQuery: string;
		onFilterChange: Callback<string>;
	}
>;

/**
 * A wrapper around the Semantic UI Dropdown component that contains a search bar and handles the activated and selected
 * state automatically. The component only works as a controlled component regarding its active item and search query.
 */
export function InMenuSearchableDropdown({
	id,
	items,
	filterQuery,
	onFilterChange,
	...dropdownProps
}: InMenuSearchableDropdownProps): JSX.Element {
	const { inputRef, managedInputAutoFocusProps } = useManagedInputAutoFocus(() => onFilterChange(''));
	const options = items.map(convertToDropdownItemProps);
	options.unshift({
		disabled: true,
		// @ts-ignore
		children() {
			return <ClearableSearchInput key="search" ref={inputRef} value={filterQuery} onChange={onFilterChange} />;
		}
	});

	const noResultsFound = filterQuery !== '' && items.filter(item => !item.disabled).length === 0;
	if (noResultsFound) {
		options.push({
			// @ts-ignore
			children() {
				return (
					<div key="no-results" className="message">
						No results found.
					</div>
				);
			}
		});
	}
	return (
		<Dropdown
			id={id}
			options={options}
			openOnFocus={false}
			selectOnBlur={false}
			selectOnNavigation={false}
			icon={dropdownProps.disabled ? null : 'angle down'}
			{...managedInputAutoFocusProps}
			{...dropdownProps}
		/>
	);
}

/*
 * Fix prop validation dev mode.
 * See https://github.com/Semantic-Org/Semantic-UI-React/pull/4029 for the corresponding implementation change.
 */
// @ts-ignore
DropdownItem.propTypes!.children = PropTypes.oneOfType([PropTypes.node, PropTypes.func]);

/**
 * Manages that the input passed to the returned ref get autofocused when the dropdown is opened. This also sets
 * closeOnBlur, but a bit delayed, because otherwise the dropdown will close immediately after opening it.
 */
function useManagedInputAutoFocus(onClose: () => void) {
	const inputRef = useRef<HTMLInputElement | null>(null);
	const [open, setOpen] = useState(false);
	const [closeOnBlur, setCloseOnBlur] = useState(false);
	useEffect(() => {
		if (open) {
			inputRef.current!.focus();
			const timeout = setTimeout(() => setCloseOnBlur(true));
			return () => clearTimeout(timeout);
		}
		return;
	}, [open]);
	const managedInputAutoFocusProps = {
		onOpen() {
			setCloseOnBlur(false);
			setOpen(true);
		},
		onClose() {
			setOpen(false);
			setCloseOnBlur(false);
			onClose();
		},
		open,
		closeOnBlur
	};
	return { inputRef, managedInputAutoFocusProps };
}

/**
 * Custom hook that transforms item definitions as suggested in the RFC
 * https://github.com/Semantic-Org/Semantic-UI-React/issues/1724 (which is not yet implemented) to the currently
 * supported options format.
 */
export function convertToDropdownItemProps(item: DropdownItemOptions): DropdownItemProps {
	const { kind, ...props } = item;

	if (kind === 'divider') {
		return {
			disabled: true,
			// @ts-ignore
			children() {
				return <Dropdown.Divider title="" {...props} />;
			}
		};
	} else if (kind === 'header') {
		return {
			disabled: true,
			// @ts-ignore
			children() {
				return <Dropdown.Header title="" {...props} />;
			}
		};
	} else {
		const { key, value, disabled, ...itemProps } = props;
		return {
			key: key ?? value ?? itemProps.text,
			value,
			disabled,
			// @ts-ignore
			children(event: unknown, dropdownItemProps: StrictDropdownItemProps) {
				return (
					<Dropdown.Item
						title=""
						data-value={dropdownItemProps.value}
						{...dropdownItemProps}
						{...itemProps}
						style={disabled ? { pointerEvents: 'none' } : undefined}
					/>
				);
			}
		};
	}
}

/** Props for ClearableSearchInput */
type ClearableSearchInputProps = {
	value: string;
	onChange: Callback<string>;
};

/** A controlled search input that gets a clear icon as soon a text is entered that allows to clear the input. */
const ClearableSearchInput = forwardRef<HTMLInputElement, ClearableSearchInputProps>(function ClearableSearchInput(
	{ value, onChange }: ClearableSearchInputProps,
	ref: ForwardedRef<HTMLInputElement>
) {
	return (
		<Input
			fluid
			placeholder="Search..."
			input={{ ref }}
			value={value}
			title=""
			icon={
				value === '' ? (
					'search'
				) : (
					<Icon
						link
						name="close"
						data-testid="clear-button"
						onClick={(e: MouseEvent) => {
							onChange('');
							e.stopPropagation();
						}}
					/>
				)
			}
			onChange={event => {
				onChange(event.target.value);
				event.stopPropagation();
			}}
			onKeyDown={(event: KeyboardEvent) => {
				if (event.key === ' ') {
					event.stopPropagation();
				}
			}}
			onClick={(e: MouseEvent) => e.stopPropagation()}
		/>
	);
});
