import { MUTATION } from 'api/Mutation';
import type { Dispatch, JSX } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { ReactUtils } from 'ts/base/ReactUtils';
import type { UserResolvedDashboardDescriptor } from 'typedefs/UserResolvedDashboardDescriptor';

/** Provides a listener function to call on favorite icon click. */
function useDashboardFavoriteListener({ dashboard, setDashboard }: DashboardFavoriteIconProps) {
	if (!dashboard) {
		return () => undefined;
	}
	const { mutate } = MUTATION.markDashboardAsFavorite.useMutation({
		async onSuccess() {
			await ReactUtils.queryClient.invalidateQueries();
		}
	});
	return async () => {
		const updatedDashboard = { ...dashboard, isFavorite: !dashboard.isFavorite };
		mutate({
			pathParams: { dashboardId: dashboard.id! },
			queryParams: { setFavorite: updatedDashboard.isFavorite }
		});
		setDashboard(updatedDashboard);
	};
}

/** Props for {@link DashboardFavoriteIcon}. */
type DashboardFavoriteIconProps = {
	dashboard: UserResolvedDashboardDescriptor | null;
	setDashboard: Dispatch<UserResolvedDashboardDescriptor | null>;
};

/** Renders the dashboard favorite icon button. */
export function DashboardFavoriteIcon({ dashboard, setDashboard }: DashboardFavoriteIconProps): JSX.Element {
	const onFavoriteIconClick = useDashboardFavoriteListener({ dashboard, setDashboard });
	const isFavorite = dashboard?.isFavorite;
	return (
		<Button
			secondary
			id="toggle-dashboard-favorite"
			icon={<Icon name={isFavorite ? 'star' : 'star outline'} color="yellow" />}
			title={isFavorite ? 'Remove dashboard from favorites' : 'Add dashboard to favorites'}
			onClick={onFavoriteIconClick}
		/>
	);
}
