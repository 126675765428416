import { QUERY } from 'api/Query';
import { UnresolvedCommitDescriptor } from 'custom-types/UnresolvedCommitDescriptor';
import { useLayoutEffect, useReducer } from 'react';
import type { Callback } from 'ts/base/Callback';
import { useAddRecentlySelectedBranch } from 'ts/base/components/branch-chooser/UseBranchInfos';
import type { TimeTravelOptions } from 'ts/base/view/ViewDescriptor';
import { ArrayUtils } from 'ts/commons/ArrayUtils';
import { GlobalBranchSelector } from 'ts/commons/GlobalBranchSelector';
import { EPointInTimeType } from 'ts/commons/time/EPointInTimeType';
import { PointInTimePicker } from 'ts/commons/time/PointInTimePicker';
import { TimeContext } from 'ts/commons/time/TimeContext';
import type { TypedPointInTime } from 'ts/commons/time/TypedPointInTime';
import type { TimeTravelButtonPropsWithEvents } from 'ts/commons/TimeTravelButton';
import { TimeTravelButton } from 'ts/commons/TimeTravelButton';
import { TimetravelUtils } from 'ts/commons/TimetravelUtils';

/**
 * Holds the shared state of the time travel button that is shared with the global branch selector. This is instanced as
 * a global singleton some views need to be able to change the state outside the regular react lifecycle.
 */
export class TimeTravelState {
	/** Global singleton instance of the time travel state. */
	public static readonly INSTANCE = new TimeTravelState();

	/** Default action when a new branch or timestamp is selected (sets the navigation hash and navigates). */
	public static readonly DEFAULT_COMMIT_SELECTION_HANDLER = (commit: UnresolvedCommitDescriptor): void =>
		TimetravelUtils.updateNavigationHash(commit);

	/** The currently selected timestamp or null for the current time. */
	public commit: UnresolvedCommitDescriptor | null = null;

	/** Custom commit selection handler (Only needed for the dashboard edit view). */
	private commitSelectionHandler?: Callback<UnresolvedCommitDescriptor>;

	/**
	 * Content type name of the current view, e.g. "Findings". Can be set to override the default as defined in the
	 * ViewDescriptor.timeTravel.contentName options
	 */
	private contentName?: string;

	/** Listeners for changes to the state of this object. Used to trigger re-renders of the dependent React components. */
	private readonly timeTravelStateChangeListeners: Array<() => void> = [];

	/** @see #contentName */
	public getContentName() {
		return this.contentName;
	}

	/** @see #contentName */
	public setContentName(contentName: string | undefined): void {
		this.contentName = contentName;
		this.notifyListeners();
	}

	/** @see #commit */
	public getCommit() {
		return this.commit;
	}

	/** @see #commit */
	public setCommit(commit: UnresolvedCommitDescriptor): void {
		this.commit = commit;
		TimetravelUtils.setCurrentCommit(commit);
		TimeTravelState.INSTANCE.getCommitSelectionHandler()(commit);
		this.notifyListeners();
	}

	/** @see #commitSelectionHandler */
	private getCommitSelectionHandler() {
		return this.commitSelectionHandler ?? TimeTravelState.DEFAULT_COMMIT_SELECTION_HANDLER;
	}

	/** @see #commitSelectionHandler */
	public setCommitSelectionHandler(commitSelectionHandler: Callback<UnresolvedCommitDescriptor> | undefined): void {
		this.commitSelectionHandler = commitSelectionHandler;
		this.notifyListeners();
	}

	/** Resets the time travel state after a navigation also resetting the commit to what the URL represents. */
	public reset(commit: UnresolvedCommitDescriptor | null): void {
		this.commit = commit;
		this.contentName = undefined;
		this.commitSelectionHandler = undefined;
		this.notifyListeners();
	}

	/**
	 * Adds the given listener to the #timeTravelStateChangeListeners that are notified whenever the state changes. The
	 * returned function removes the listener again.
	 */
	public addTimeTravelStateChangeListener(timeTravelStateChangeListener: () => void): () => void {
		this.timeTravelStateChangeListeners.push(timeTravelStateChangeListener);
		return () => {
			ArrayUtils.remove(this.timeTravelStateChangeListeners, timeTravelStateChangeListener);
		};
	}

	private notifyListeners() {
		this.timeTravelStateChangeListeners.forEach(listener => listener());
	}
}

/** Renders the global branch chooser and calls the commit selection handler with the commit to navigate to. */
export function GlobalBranchSelectorWrapper({ projectIds }: { projectIds: string[] }) {
	useRerenderOnDelayedMutableJumpToTimeOptionsChanged();
	const commit = TimeTravelState.INSTANCE.getCommit();
	const registerSelectedBranch = useAddRecentlySelectedBranch(projectIds[0]);
	return (
		<GlobalBranchSelector
			selectedBranch={commit?.branchName}
			projectIds={projectIds}
			onBranchChanged={branchName => {
				registerSelectedBranch(branchName);
				const timestamp = commit?.getTimestamp() ?? null;
				const newCommit = new UnresolvedCommitDescriptor(timestamp, branchName);
				TimeTravelState.INSTANCE.setCommit(newCommit);
			}}
		/>
	);
}

/** Hook that triggers re-render when the time travel state changed. */
function useRerenderOnDelayedMutableJumpToTimeOptionsChanged() {
	const [, rerender] = useReducer(state => state + 1, 0);
	useLayoutEffect(() => TimeTravelState.INSTANCE.addTimeTravelStateChangeListener(rerender), []);
}

/**
 * Jump to the given timestamp or commit via the current NavigationHash.
 *
 * @param newPointInTime The commit or time picker value to jump to
 */
async function jumpToTime(newPointInTime: TypedPointInTime): Promise<UnresolvedCommitDescriptor> {
	const commit = TimeTravelState.INSTANCE.getCommit();
	if (newPointInTime.type === EPointInTimeType.TIMESPAN && newPointInTime.value.days === 0) {
		// Set timestamp to HEAD
		return UnresolvedCommitDescriptor.createCommitFromTimestamp(null, commit);
	}
	if (newPointInTime.type === EPointInTimeType.GIT_TAG) {
		return QUERY.resolveTag(newPointInTime.value.projectId, newPointInTime.value)
			.fetch()
			.then(
				resolvedCommit => new UnresolvedCommitDescriptor(resolvedCommit?.timestamp, resolvedCommit?.branchName)
			);
	}
	const newCommit = await new TimeContext().resolveCommit(newPointInTime);
	if (newPointInTime.type === EPointInTimeType.REVISION && newPointInTime.value.branch != null) {
		return new UnresolvedCommitDescriptor(newCommit.getTimestamp(), newPointInTime.value.branch);
	}
	return UnresolvedCommitDescriptor.createCommitFromTimestamp(newCommit.getTimestamp(), commit);
}

/** A wrapper around the time travel button that applies the time travel state and handles click and clear actions. */
export function TimeTravelButtonWrapper({
	timeTravelOptions,
	projectIds
}: {
	timeTravelOptions: TimeTravelOptions;
	projectIds: string[];
}) {
	const props = useTimeTravelButtonProps(timeTravelOptions, projectIds);
	return <TimeTravelButton {...props} />;
}

function useTimeTravelButtonProps(
	timeTravelOptions: TimeTravelOptions,
	projectIds: string[]
): TimeTravelButtonPropsWithEvents {
	useRerenderOnDelayedMutableJumpToTimeOptionsChanged();
	const commit = TimeTravelState.INSTANCE.getCommit();

	return {
		timestamp: commit?.timestamp ?? null,
		contentName: TimeTravelState.INSTANCE.getContentName() ?? timeTravelOptions.contentName,
		until: Boolean(timeTravelOptions.until),

		/** Clears the currently set time. */
		onClear() {
			if (commit == null) {
				return;
			}
			TimeTravelState.INSTANCE.setCommit(new UnresolvedCommitDescriptor(null, commit.branchName));
		},

		/** Function handling the select timestamp button press. A time revision picker dialog will be shown. */
		async onJump() {
			const defaultValue = TimetravelUtils.getCurrentTime();
			const time = await PointInTimePicker.showDialog(projectIds, undefined, false, defaultValue);

			if (time.type === EPointInTimeType.BASELINE) {
				// Save selected baseline to display the baseline's name in the TimePicker
				TimetravelUtils.saveLastSelectedBaselineToStorage(time.value);
			}
			if (time.type === EPointInTimeType.GIT_TAG) {
				// Save selected git tag to display the git tag's name in the TimePicker
				TimetravelUtils.saveLastSelectedGitTagToStorage({
					name: time.value.refName,
					project: time.value.projectId
				});
			}

			TimetravelUtils.setCurrentTime(time);
			TimeTravelState.INSTANCE.setCommit(await jumpToTime(time));
		}
	};
}
