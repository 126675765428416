import { parsePath } from 'react-router-dom';
import { NEXT_NAVIGATION, ROUTER } from 'ts/base/routing/Router';
import { ObjectUtils } from 'ts/commons/ObjectUtils';
import { StringUtils } from 'ts/commons/StringUtils';
import { DO_NOT_RELOAD_VIEW_STATE } from '../hooks/UseLocation';

/** Navigates to the given URL relative to the base URL (excl. protocol, base URL) using React Router. */
export function navigateTo(href: string, replace?: boolean, state?: unknown): void {
	// If the URL hasn't changed, a regular <a> will do a replace instead of
	// a push, so do the same here.
	const path = parsePath(StringUtils.ensureStartsWith(href, '/'));
	const shouldReplace = replace ?? ObjectUtils.deepEqual(ROUTER.state.location, path);
	NEXT_NAVIGATION.isSilent = state === DO_NOT_RELOAD_VIEW_STATE;
	queueMicrotask(() => {
		NEXT_NAVIGATION.isSilent = false;
	});
	void ROUTER.navigate(path, { replace: shouldReplace, state });
}

/** Determines whether the given full URL can be navigated to using the React Router. */
export function shouldBrowserHandle(href: string): boolean {
	let url: URL;
	try {
		url = new URL(href);
	} catch (e) {
		return false;
	}
	return (
		// Let browser handle external links
		window.location.host !== url.host ||
		// Let the browser handle mailto links and similar
		!url.protocol.startsWith('http')
	);
}
