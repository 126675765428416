import { Label } from 'semantic-ui-react';
import { openModal } from 'ts/commons/modal/ModalUtils';

/** Displays the about Teamscale dialog. */
export function openAboutTeamscaleModal(versionDetails: string) {
	openModal({
		title: 'About Teamscale',
		contentRenderer: () => <AboutDialogContent versionDetails={versionDetails} />
	});
}

function AboutDialogContent({ versionDetails }: { versionDetails: string }) {
	return (
		<div className="ts-dialog-about">
			<div className="ui basic center aligned segment">
				<img className="teamscale-logo" src="images/teamscale-logo.svg" alt="Teamscale logo" />

				<div>
					<Label>{versionDetails}</Label>
				</div>

				<h4>Because Code Quality Matters</h4>
				<p>Teamscale supports your team to analyze, monitor, and improve the quality of your code.</p>

				<p>
					<a href="https://teamscale.com/support">Questions? Contact us!</a>
				</p>
			</div>
		</div>
	);
}
