import type { ComponentProps } from 'react';
import type DateTimePicker from 'react-datetime-picker';
import { Icon } from 'semantic-ui-react';
import styles from './DefaultCalendarStyle.module.less';

export type DateTimePickerProps = ComponentProps<typeof DateTimePicker>;

export default function useDefaultCalendarProps(dateOnly: boolean): DateTimePickerProps {
	const defaultCalendarProps: DateTimePickerProps = {
		amPmAriaLabel: 'Select AM/PM',
		calendarAriaLabel: 'Toggle calendar',
		calendarProps: {
			className: styles.calendar
		},
		calendarIcon: <Icon name="calendar" />,
		className: styles.datetime_picker,
		clearAriaLabel: 'Clear value',
		clearIcon: null,
		closeWidgets: true,
		'data-testid': 'datetime-picker',
		dayAriaLabel: 'Day',
		dayPlaceholder: 'DD',
		disableClock: true,
		hourAriaLabel: 'Hour',
		hourPlaceholder: 'hh',
		minuteAriaLabel: 'Minute',
		minutePlaceholder: 'mm',
		monthAriaLabel: 'Month',
		monthPlaceholder: 'MM',
		name: dateOnly ? 'date' : 'datetime',
		nativeInputAriaLabel: 'Date input',
		yearAriaLabel: 'Year',
		yearPlaceholder: 'YYYY'
	};
	if (dateOnly) {
		return { ...defaultCalendarProps, format: 'yyyy-MM-dd' };
	} else {
		return { ...defaultCalendarProps, format: 'yyyy-MM-dd, HH:mm' };
	}
}
