import { QUERY } from 'api/Query';
import type { UnresolvedCommitDescriptor } from 'custom-types/UnresolvedCommitDescriptor';
import * as CompareCodeRenderComponentTemplate from 'soy/perspectives/compare/CompareCodeRenderComponentTemplate.soy.generated';
import * as style from 'ts-closure-library/lib/style/style';
import * as soy from 'ts/base/soy/SoyRenderer';
import { DateUtils } from 'ts/commons/DateUtils';
import { NavigationHash } from 'ts/commons/NavigationHash';
import { StringUtils } from 'ts/commons/StringUtils';
import { ToastNotification } from 'ts/commons/ToastNotification';
import type { RepositoryLogEntryWithCommit } from 'ts/data/RepositoryLogEntryWithCommit';
import { wrapRepositoryLogEntry } from 'ts/data/RepositoryLogEntryWithCommit';
import type { DerivedTestCoverageInfo } from 'typedefs/DerivedTestCoverageInfo';
import { ELanguage } from 'typedefs/ELanguage';
import type { FindingContent } from 'typedefs/FindingContent';
import type { FormattedTokenElementInfo } from 'typedefs/FormattedTokenElementInfo';
import type { LineCoverageInfo } from 'typedefs/LineCoverageInfo';
import type { RepositoryLogEntry } from 'typedefs/RepositoryLogEntry';
import type { SelfContainedFinding } from 'typedefs/SelfContainedFinding';
import type { TrackedFinding } from 'typedefs/TrackedFinding';
import type { WrappedLogEntry } from 'typedefs/WrappedLogEntry';
import { DiffSourceFormatter } from './DiffSourceFormatter';

/**
 * The content that is subject to comparison (either left or right). This consists of the element content and also any
 * associated data such as findings.
 */
export class CompareContent {
	/**
	 * The commit for which the code is displayed. May be <code>null</code> to indicate the most recent commit on the
	 * default branch.
	 */
	private readonly commit: UnresolvedCommitDescriptor | null;

	/** The line to navigate to. May be null to indicate no specific line. */
	private readonly initialLine: number | null;

	/** The line to start rendering the code content at. */
	private readonly startLine?;

	/** The line to end rendering the code content at. */
	private readonly endLine?;

	/** The test coverage info for the element. */
	private lineCoverageInfo: LineCoverageInfo | DerivedTestCoverageInfo | null = null;

	/**
	 * The corresponding log entry. This is read from a SOY template and not used in this class. May be null/undefined
	 * if the file to be compared is not found.
	 */
	private logEntry: RepositoryLogEntryWithCommit | null = null;

	/** The content. */
	private content: FormattedTokenElementInfo | FindingContent | null = null;

	/** The number of lines of the content. */
	public numberOfLines = 0;

	/** The findings. */
	private findings: TrackedFinding[] | SelfContainedFinding[] = [];

	/** The source formatter used (if this has already been created). */
	private formatter: DiffSourceFormatter | null = null;

	/** The Regions which have been changed on the last commit */
	private changeRegionsPreviousCommit: number[] | null = [];

	public constructor(
		private readonly project: string,
		private readonly uniformPath: string,
		commit: UnresolvedCommitDescriptor | null,
		initialLine: number | null,
		startLine?: number,
		endLine?: number
	) {
		this.commit = commit;
		this.initialLine = initialLine;
		this.startLine = startLine;
		this.endLine = endLine;
	}

	/**
	 * @param historyToken The part of the history token describing the content, which consists of the project, the path
	 *   and (optionally) the commit.
	 */
	public static fromHistoryToken(prefix: 'left' | 'right', hash: NavigationHash): CompareContent {
		const project = hash.getString(`${prefix}-project`)!;
		const uniformPath = hash.getString(`${prefix}-path`)!;
		const commit = hash.getCommitParameter(`${prefix}-commit`);
		const initialLine = hash.getNumber(`${prefix}-line`);
		return new CompareContent(project, uniformPath, commit, initialLine);
	}

	/** @returns The project. */
	public getProject(): string {
		return this.project;
	}

	/** @returns The start line. */
	public getStartLine(): number | undefined {
		return this.startLine;
	}

	/** @returns The end line. */
	public getEndLine(): number | undefined {
		return this.endLine;
	}

	/** @returns The uniform path. */
	public getUniformPath(): string {
		return this.uniformPath;
	}

	/**
	 * @returns The relevant commit for which the code is displayed. May be <code>null</code> to indicate the most
	 *   recent commit on the default branch.
	 */
	public getCommit(): UnresolvedCommitDescriptor | null {
		return this.commit;
	}

	/** @returns The initial line. */
	public getInitialLine(): number | null {
		return this.initialLine;
	}

	/** @returns The number of lines. */
	public getNumberOfLines(): number {
		return this.numberOfLines;
	}

	/** @returns The number of content. */
	public getContent(): FormattedTokenElementInfo | FindingContent | null {
		return this.content;
	}

	/** Initializes this.content and this.numberOfLines with default, empty contents. */
	private setEmptyContent(): void {
		this.content = {
			tokens: [],
			styles: [],
			codeLinks: {},
			issueReferencesByCommentOffset: {},
			issueReferences: [],
			uniformPath: '',
			language: ELanguage.TEXT.name,
			text: '',
			filterDeletions: [],
			details: []
		};
		this.numberOfLines = 1;
	}

	/**
	 * @param changeRegions The regions which should be highlighted yellow. Contains the character offsets, which are
	 *   absolute to the start of the file, organized in pairs (e.g. start of change region at index 0 and end at index
	 *   1).
	 */
	public setPreviousCommitChangeRegions(changeRegions: number[]): void {
		this.changeRegionsPreviousCommit = changeRegions;
	}

	/** Preloads all required data. */
	public async preload(): Promise<void> {
		// Comparison with local file.
		// The constant corresponds to ElementComparisonService.LOCAL_DISK_ACCESS_MARKER.
		if (this.project === '_local_') {
			return this.preloadForLocalFile();
		}
		const contentLoader = QUERY.getFormattedTokenElementInfo(this.project, {
			'uniform-path': this.uniformPath,
			t: this.commit ?? undefined
		}).fetch();
		const resourceFindingsLoader = QUERY.getFindings(this.project, {
			'uniform-path': this.uniformPath,
			'only-spec-item-findings': false,
			t: this.commit ?? undefined,
			pretty: false,
			'included-paths': [this.uniformPath]
		}).fetch();
		const logEntryLoader = QUERY.getLastChangeLogEntry(this.project, this.uniformPath, {
			t: this.commit ?? undefined,
			'exclude-non-code-commits': true
		}).fetch();

		const content = await contentLoader;
		const findings = await resourceFindingsLoader;
		const logEntry = await logEntryLoader;

		const prettyPrint = NavigationHash.getCurrent().getBoolean('pretty');
		const lineCoverageInfo = await QUERY.getTestCoverage(this.project, this.uniformPath, {
			pretty: prettyPrint,
			t: this.commit ?? undefined
		}).fetch();

		this.setData(content, findings, logEntry, lineCoverageInfo);
	}

	private async preloadForLocalFile() {
		try {
			this.content = await QUERY.getExternalFileWithPreprocessing(this.uniformPath).fetch();
			this.contentReceived();
		} catch (e) {
			this.setEmptyContent();
			ToastNotification.error(e.message);
		}
		this.logEntry = null;
		this.findings = [];
	}

	/** Set all necessary data for the compare content. */
	public setData(
		content: FormattedTokenElementInfo | FindingContent | null,
		findings: TrackedFinding[] | SelfContainedFinding[],
		logEntry: RepositoryLogEntry | WrappedLogEntry | null,
		lineCoverageInfo: LineCoverageInfo | null = null
	): void {
		this.content = content;
		this.findings = findings;
		this.lineCoverageInfo = lineCoverageInfo;
		if (logEntry) {
			this.logEntry = wrapRepositoryLogEntry(logEntry);
		} else {
			this.logEntry = null;
		}
		this.contentReceived();
	}

	/** Calculates the {@link #numberOfLines} or sets an empty content. */
	private contentReceived(): void {
		if (this.content != null) {
			this.numberOfLines = StringUtils.splitLines(this.content.text).length;
		} else {
			// Did not find the path at this timestamp
			this.setEmptyContent();
		}
	}

	/**
	 * Renders the source code for this content into the given parent element. If no content is available, a "file not
	 * found"-message is rendered instead.
	 *
	 * @param parentElement The element to render into
	 * @param changeLines The array describing the changed lines in the diff. See Java class DiffDescriptor for details.
	 * @param changeRegions The array describing the changed regions in the diff. See Java class DiffDescriptor for
	 *   details.
	 * @param otherChangedLines The array describing the changed lines in the other side of the diff. See Java class
	 *   DiffDescriptor for details.
	 * @param isLeft Whether this is the left or right source of the compare.
	 * @param highlightInLastCommitChangedRegions Whether the differences to the previous Commit should get highlighted
	 *   or not
	 * @param showTestCoverage Whether to annotate the code with line coverage
	 * @param codeFontSize
	 * @param isColorBlindModeEnabled Whether to show the color-blind friendly colors
	 */
	public renderInto(
		parentElement: Element,
		changeLines: number[],
		changeRegions: number[],
		otherChangedLines: number[],
		isLeft: boolean,
		highlightInLastCommitChangedRegions: boolean,
		showTestCoverage: boolean,
		codeFontSize: number,
		isColorBlindModeEnabled: boolean
	): void {
		if (this.content == null || this.content.uniformPath === '') {
			this.renderFileNotFoundMessage(parentElement);
			return;
		}
		if (!highlightInLastCommitChangedRegions) {
			this.changeRegionsPreviousCommit = null;
		}
		this.formatter = new DiffSourceFormatter(
			this.content,
			this.project,
			this.commit!,
			changeLines,
			changeRegions,
			otherChangedLines,
			isLeft,
			this.changeRegionsPreviousCommit
		);
		this.appendFormatter(parentElement, isLeft, showTestCoverage, codeFontSize, isColorBlindModeEnabled);
	}

	private appendFormatter(
		parentElement: Element,
		isLeft: boolean,
		showTestCoverage: boolean,
		codeFontSize: number,
		isColorBlindModeEnabled: boolean
	) {
		parentElement.appendChild(
			this.formatter!.getFormattedSource(
				this.startLine,
				this.endLine,
				false,
				isLeft ? 0 : 1,
				codeFontSize,
				isColorBlindModeEnabled
			)
		);
		this.formatter!.addFindingBars(this.findings, true, this.project);
		this.formatter!.showTestCoverageHighlighting(
			showTestCoverage,
			this.lineCoverageInfo as LineCoverageInfo,
			isColorBlindModeEnabled
		);
	}

	private renderFileNotFoundMessage(parentElement: Element) {
		let formattedTimestamp = 'unknown';
		if (this.commit != null) {
			formattedTimestamp = DateUtils.formatTimestamp(this.commit.getTimestamp());
		}
		parentElement.appendChild(
			soy.renderAsElement(CompareCodeRenderComponentTemplate.fileNotFound, {
				filepath: this.uniformPath,
				formattedTimestamp
			})
		);
	}

	/**
	 * Maps a line number to a vertical screen offset of the HTML element representing the line. Returns -1 if no
	 * conversion is possible.
	 *
	 * @param line The 1-based line for which to obtain pixel coords.
	 */
	public lineToScreenY(line: number): number {
		if (!this.formatter) {
			return -1;
		}
		if (line < (this.startLine ?? 1)) {
			line = this.startLine ?? 1;
		}
		if (line > (this.endLine ?? this.numberOfLines)) {
			line = this.endLine ?? this.numberOfLines;
		}
		const lineElement = this.formatter.getLineElement(line);
		return style.getPageOffsetTop(lineElement);
	}

	/**
	 * Maps a line number to a vertical scrolling position. Returns -1 if no conversion is possible.
	 *
	 * @param line The 1-based line for which to obtain pixel coordinates (this can be a fraction line)
	 */
	public fractionalLineToScrollY(line: number): number {
		if (line < (this.startLine ?? 1)) {
			line = this.startLine ?? 1;
		}
		if (line >= (this.endLine ?? this.numberOfLines)) {
			line = this.endLine ?? this.numberOfLines;
		}
		if (!this.formatter) {
			return -1;
		}
		const lineElement = this.formatter.getLineElement(Math.floor(line)) as HTMLElement;
		return lineElement.offsetTop + (line - Math.floor(line)) * lineElement.clientHeight;
	}

	/** Disposes the elements of the CompareContent view (the formatter containing tooltip events) */
	public dispose(): void {
		this.formatter?.dispose();
	}
}
