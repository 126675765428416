import { UnresolvedCommitDescriptor } from 'custom-types/UnresolvedCommitDescriptor';
import type { DefinedPointInTime } from 'ts/commons/time/DefinedPointInTime';
import { NavigationHash } from './NavigationHash';
import type { TypedPointInTime } from './time/TypedPointInTime';
import { UIUtils } from './UIUtils';

/** Component for selecting and jumping to a timestamp via the current NavigationHash. */
export class TimetravelUtils {
	/** The key for the local storage containing the commit parameter. */
	public static COMMIT_STORAGE_KEY = 'current-commit';

	/** The key for the local storage containing the current typed point in time. */
	public static TIME_STORAGE_KEY = 'current-time';

	/** The key for the local storage containing the last selected baseline. */
	public static LAST_SELECTED_BASELINE_STORAGE_KEY = 'lastSelectedBaseline';

	/** The key for the local storage containing the last selected git tag. */
	public static LAST_SELECTED_GIT_TAG_STORAGE_KEY = 'lastSelectedGitTag';

	/**
	 * Stores the current commit in the browser local storage.
	 *
	 * @param commit The commit to store.
	 */
	public static setCurrentCommit(commit: UnresolvedCommitDescriptor | null): void {
		const storage = UIUtils.getLocalStorage();
		if (commit != null) {
			storage.set(TimetravelUtils.COMMIT_STORAGE_KEY, commit.toString());
		} else {
			storage.set(TimetravelUtils.COMMIT_STORAGE_KEY, null);
		}
	}

	/** Stores the current point in time in the browser local storage. */
	public static setCurrentTime(time: TypedPointInTime | null): void {
		const storage = UIUtils.getLocalStorage();
		storage.set(TimetravelUtils.TIME_STORAGE_KEY, time);
	}

	/**
	 * Retrieves the current commit from the browser local storage.
	 *
	 * @returns The stored commit or null if no commit was stored.
	 */
	public static getLastSelectedCommitFromStorage(): UnresolvedCommitDescriptor | null {
		const storage = UIUtils.getLocalStorage();
		const commitFromStorage = storage.get(TimetravelUtils.COMMIT_STORAGE_KEY) as string | null;
		if (commitFromStorage != null) {
			return UnresolvedCommitDescriptor.fromString(commitFromStorage);
		}
		return null;
	}

	/** Retrieves the current point in time from the browser local storage. */
	public static getCurrentTime(): TypedPointInTime | null {
		const storage = UIUtils.getLocalStorage();
		return storage.get(TimetravelUtils.TIME_STORAGE_KEY);
	}

	/** @returns Whether timetravel is activated */
	public static isTimetravelActive(): boolean {
		const commit = NavigationHash.getCurrentCommit();
		return commit != null && !commit.isLatestRevision();
	}

	/** Updates the navigation hash to the current timestamp. */
	public static updateNavigationHash(commit: UnresolvedCommitDescriptor): void {
		const hash = NavigationHash.getCurrent();
		hash.setCommit(commit);
		hash.navigate();
	}

	/**
	 * Returns the last selected baseline from the local storage. Returns undefined if no last selected baseline is
	 * present.
	 */
	public static getLastSelectedBaselineFromStorage(): DefinedPointInTime | undefined {
		return UIUtils.getLocalStorage().get(TimetravelUtils.LAST_SELECTED_BASELINE_STORAGE_KEY);
	}

	/**
	 * Returns the last selected baseline from the local storage. Returns undefined if no last selected baseline is
	 * present.
	 */
	public static getLastSelectedGitTagFromStorage(): DefinedPointInTime | undefined {
		return UIUtils.getLocalStorage().get(TimetravelUtils.LAST_SELECTED_GIT_TAG_STORAGE_KEY);
	}

	/**
	 * Saves the last selected baseline to the local storage. Can later be retrieved via
	 * {@link getLastSelectedBaselineFromStorage}.
	 */
	public static saveLastSelectedBaselineToStorage(baseline: DefinedPointInTime): void {
		UIUtils.getLocalStorage().set(TimetravelUtils.LAST_SELECTED_BASELINE_STORAGE_KEY, baseline);
	}

	/**
	 * Saves the last selected git tag to the local storage. Can later be retrieved via
	 * {@link getLastSelectedGitTagFromStorage}.
	 */
	public static saveLastSelectedGitTagToStorage(gitTag: DefinedPointInTime): void {
		UIUtils.getLocalStorage().set(TimetravelUtils.LAST_SELECTED_GIT_TAG_STORAGE_KEY, gitTag);
	}
}
