import { QUERY } from 'api/Query';
import type { JSX } from 'react';
import type { StrictImageProps } from 'semantic-ui-react';
import { Image } from 'semantic-ui-react';
import type { EAvatarSize } from 'typedefs/EAvatarSize';
import type { User } from 'typedefs/User';

/** Props for Avatar. */
type AvatarProps = JSX.IntrinsicElements['img'] &
	StrictImageProps & {
		/** The user for which to render the avatar. */
		user?: User;
		username: string;
		/** The size of the avatar. */
		avatarSize: EAvatarSize;
	};

/** Component that shows the avatar image of the given user. */
export function Avatar({ user, username, avatarSize, ...imgProps }: AvatarProps): JSX.Element {
	const resultingUsername = (user?.username ?? username) || 'unknown';
	let altText = username;
	if (user) {
		altText = `${user.firstName} ${user.lastName}`;
	}
	const src = QUERY.getUserAvatar(resultingUsername, { size: avatarSize.name, 'force-reload': Date.now() }).url;
	return <Image avatar loading="lazy" src={src} alt={altText} {...imgProps} />;
}
