import clsx from 'clsx';
import type { JSX, PropsWithChildren, ReactNode } from 'react';
import { Container } from 'semantic-ui-react';
import { ValidationContextProvider, ValidatorContainer } from 'ts/commons/components/ValidationContext';
import { ViewTitle } from 'ts/commons/components/ViewTitle';
import styles from './ViewPage.module.less';

/**
 * Different variants for view sizing:
 *
 * - Small-max-width: centered view with small maximum width.
 * - Relative-limited-width: centered container with relative width of 80% with a reasonable minimum and maximum width.
 * - Full-width: container that uses the full available width.
 */
export type ViewPageVariant = 'small-max-width' | 'relative-limited-width' | 'full-width';

/** Props for ViewPage. */
export type ViewPageProps = PropsWithChildren<{
	title: ReactNode;
	variant: ViewPageVariant;
	count?: number;
	description?: ReactNode;
	className?: string;
	buttons?: ReactNode;
}>;

/** An element for views that has a centered container, a header and a validator. */
export function ViewPage({
	title,
	count,
	description,
	buttons,
	children,
	variant,
	className
}: ViewPageProps): JSX.Element {
	return (
		<ValidationContextProvider>
			<Container className={clsx(getClassNameForVariant(variant), className)}>
				<ViewTitle title={title} count={count} buttons={buttons} />
				{description ? <div>{description}</div> : null}
				<ValidatorContainer />
				{children}
			</Container>
		</ValidationContextProvider>
	);
}

function getClassNameForVariant(variant: ViewPageVariant) {
	switch (variant) {
		case 'relative-limited-width':
			return styles.reactiveContainer!;
		case 'full-width':
			return '!w-full';
		default:
			return styles.pageContainer!;
	}
}
