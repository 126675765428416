import type { JSX } from 'react';
import { RouterProvider } from 'react-router-dom';
import { DebuggingPanel } from 'ts/base/perspective/debugger/DebuggingPanel';
import { BaseProviders } from 'ts/base/ReactUtils';
import { ROUTER } from 'ts/base/routing/Router';
import { ErrorReportingComponent } from './base/components/ErrorReportingComponent';

/** Top level router that instantiates the perspective named in the current browser url. */
export function App(): JSX.Element {
	return (
		<BaseProviders>
			<ErrorReportingComponent>
				<RouterProvider router={ROUTER} />
				<DebuggingPanel />
			</ErrorReportingComponent>
		</BaseProviders>
	);
}
