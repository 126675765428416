import type { JSX } from 'react';
import type { ColumnDef, CoreRow, RowData } from '@tanstack/react-table';
import { Radio } from 'semantic-ui-react';
import type { CellProps } from 'ts/base/table/react-table';

/** Provides a column definition that represents a row selection via radio buttons. */
export function getTableRowRadioButtonsColumn<T extends RowData>(
	dataTestIdFieldName?: keyof CoreRow<T>['original']
): ColumnDef<T> {
	return {
		id: 'radio',
		header: '',
		cell({ row }: CellProps<T>): JSX.Element {
			const selected = row.getIsSelected();
			const dataTestId = dataTestIdFieldName !== undefined ? row.original[dataTestIdFieldName] : '';
			return <Radio checked={selected} onChange={row.getToggleSelectedHandler()} data-testid={dataTestId} />;
		},
		meta: {
			collapsing: true
		}
	};
}
