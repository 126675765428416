import { Assertions } from 'ts/commons/Assertions';
import { AssessmentFormatter, type AssessmentFormatterOptions } from 'ts/commons/formatter/AssessmentFormatter';
import { CounterSetFormatter } from 'ts/commons/formatter/CounterSetFormatter';
import { StringValueFormatter } from 'ts/commons/formatter/StringValueFormatter';
import { EMetricValueType } from 'typedefs/EMetricValueType';
import type { GenericOptions, MetricFormatterBase } from './MetricFormatterBase';
import type { NumericFormatterOptions } from './NumericValueFormatter';
import { NumericValueFormatter } from './NumericValueFormatter';
import type { TgaFormatterOptions } from './TestGapAssessmentFormatter';
import { TestGapAssessmentFormatter } from './TestGapAssessmentFormatter';
import type { TimestampFormatterOptions } from './TimestampMetricFormatter';
import { TimestampMetricFormatter } from './TimestampMetricFormatter';

/** A factory that creates different metric value formatters. */
export class MetricFormatterFactory {
	/**
	 * Creates a matching formatter for the given value type. Will call failForUnsupportedMetricType() for unknown value
	 * types.
	 *
	 * @param valueType The type of the value to format (e.g. 'ASSESSMENT')
	 * @param options - Further formatting options (optional). See {@link MetricFormatterBase} for available options.
	 */
	public static createFormatterForMetric(
		valueType: string,
		options: GenericOptions | { subType: string } = {}
	): MetricFormatterBase<GenericOptions | null, unknown> | null {
		switch (valueType) {
			case EMetricValueType.NUMERIC.name:
				return new NumericValueFormatter(options as NumericFormatterOptions);
			case EMetricValueType.ASSESSMENT.name:
				return new AssessmentFormatter(options as AssessmentFormatterOptions);
			case EMetricValueType.TIMESTAMP.name:
				return new TimestampMetricFormatter(options as TimestampFormatterOptions);
			case EMetricValueType.COUNTER_SET.name:
				if (options.subType === EMetricValueType.NUMERIC.name) {
					return new NumericValueFormatter(options as NumericFormatterOptions);
				} else if (options.subType === EMetricValueType.ASSESSMENT.name) {
					return new TestGapAssessmentFormatter(options as TgaFormatterOptions);
				}
				return new CounterSetFormatter(options);
			case EMetricValueType.STRING.name:
				return new StringValueFormatter(options as GenericOptions);
			default:
				MetricFormatterFactory.failForUnsupportedMetricType(valueType);
				return null;
		}
	}

	/**
	 * Fails with an assertion for the given unsupported value type. This is used for metric types that are only used in
	 * hidden metrics and shouldn't occur in the UI.
	 *
	 * @param valueType - The metric value type to fail for.
	 */
	private static failForUnsupportedMetricType(valueType: string): void {
		Assertions.fail('Formatting metrics of type ' + valueType + ' is currently not supported.');
	}
}
