import { QUERY } from 'api/Query';
import { Message, Table } from 'semantic-ui-react';

/** The user history dialog that shows how many Teamscale users and committers were active in a project. */
export function UserHistoryDialog() {
	const userActivities = QUERY.calculateActiveUsersStatistics().useSuspendingQuery();
	const committerActivities = QUERY.calculateCommitterStatistics().useSuspendingQuery();
	if (userActivities.length === 0 && committerActivities.length === 0) {
		return <Message>No activities found.</Message>;
	}
	return (
		<Table unstackable striped compact selectable className="borderless framed body-code-table">
			<Table.Header>
				<Table.Row>
					<Table.HeaderCell>Timespan</Table.HeaderCell>
					<Table.HeaderCell>Teamscale Users</Table.HeaderCell>
					<Table.HeaderCell>Committers</Table.HeaderCell>
				</Table.Row>
			</Table.Header>
			<Table.Body>
				{userActivities.map((entry, index) => (
					<Table.Row key={entry.since + index}>
						<Table.Cell>{entry.since}</Table.Cell>
						<Table.Cell className="right aligned">{entry.count}</Table.Cell>
						<Table.Cell className="right aligned">{committerActivities[index]!.count}</Table.Cell>
					</Table.Row>
				))}
			</Table.Body>
		</Table>
	);
}
