/** Class that can dynamically load Javascript libraries. */
export class JsLibraryLoader {
	/**
	 * Stores the current and past requested libraries (as promises) so that follow-up requests for the same library can
	 * re-use the existing promises.
	 */
	private static FLOT_CACHE?: Promise<void>;

	/**
	 * Loads the flot library asynchronously while ensuring that potential earlier or parallel requests for the same
	 * library are handled accordingly.
	 *
	 * @deprecated Use recharts or chart.js instead
	 */
	public static async loadFlot(): Promise<void> {
		if (JsLibraryLoader.FLOT_CACHE) {
			return JsLibraryLoader.FLOT_CACHE;
		}
		JsLibraryLoader.FLOT_CACHE = new Promise(resolve => {
			JsLibraryLoader.loadScript('js-libs/flot-all.min.js', resolve);
		});
		return JsLibraryLoader.FLOT_CACHE;
	}

	/**
	 * Loads the given script url by appending a script tag to the body of the page. We cannot load this via jquery as
	 * this internally calls eval(), which we do not allow in our content security policy.
	 */
	private static loadScript(url: string, callback: () => void): void {
		const script = document.createElement('script');
		script.type = 'text/javascript';
		script.onload = callback;
		script.src = url;
		document.head.appendChild(script);
	}
}
