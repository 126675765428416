import clsx from 'clsx';
import type { ChangeEventHandler } from 'react';
import { useState, type JSX } from 'react';
import { Button, Input } from 'semantic-ui-react';
import type { Callback } from 'ts/base/Callback';
import styles from './FileInput.module.less';

/** Props for FileInput. */
type FileUploadProps = Omit<JSX.IntrinsicElements['input'], 'onChange'> & {
	/** The title of the button. */
	title: string;
	/** Called whenever the selected files change. */
	onChange?: Callback<File[]>;
	/** Returns a string representation of the selected files to be shown in the file input field. */
	fileSummary?: (files: File[]) => string;
};

/** Creates a Semantic UI styled file upload field. */
export function FileInput({
	title,
	placeholder,
	className,
	onChange,
	fileSummary = getFileSummary,
	...inputProps
}: FileUploadProps): JSX.Element {
	const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
	const onFilesChanged: ChangeEventHandler<HTMLInputElement> = event => {
		const files = Array.from(event.target.files ?? []);
		if (files.length > 0) {
			setSelectedFiles(files);
			onChange?.(files);
		}
	};
	return (
		<Input fluid action>
			<input type="text" disabled placeholder={placeholder} value={fileSummary(selectedFiles)} />
			<Button type="button">
				{title}
				<input
					type="file"
					onChange={onFilesChanged}
					className={clsx(styles.fileInput, className)}
					{...inputProps}
				/>
			</Button>
		</Input>
	);
}

/**
 * Returns a summary of the file names and the total count of files to be shown in the textfield for an input of type
 * file.
 */
function getFileSummary(files: File[]): string {
	let summary = '';
	const fileCount = files.length;
	if (fileCount > 1) {
		summary = `(${fileCount}) `;
	}
	return summary + files.map(file => file.name).join('; ');
}
