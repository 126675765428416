import { ServiceCallError } from 'api/ServiceCallError';
import { Notyf } from 'notyf';
import 'notyf/notyf.min.css';
import type { NotyfNotification } from 'notyf/notyf.models';
import type { INotyfNotificationOptions } from 'notyf/notyf.options';
import '../../styles/notyf.less';

const AUTO_HIDE_AFTER_MSECS = 6000;

/**
 * Use this class to display toasts (i.e., short and usually disappearing messages). We use the Notyf library under the
 * hood ( https://github.com/caroso1222/notyf )
 */
export class ToastNotification {
	private static readonly NOTYF_INSTANCE = ToastNotification.createNotyfInstance();

	/** The message showing "Teamscale is not reachable", which we store to avoid duplicate messages on the page. */
	private static activeServerNotReachableNotification?: NotyfNotification = undefined;

	/** Options to set for sticky (=non-vanishing) messages. */
	public static STICKY_TOASTER_OPTIONS: Partial<INotyfNotificationOptions> = {
		duration: Number.MAX_SAFE_INTEGER / 10,
		dismissible: true
	};

	/** Options to set for a message to be placed at the bottom-center. */
	public static BOTTOM_CENTER_OPTIONS: Partial<INotyfNotificationOptions> = {
		position: {
			x: 'center',
			y: 'bottom'
		}
	};

	/** Displays a success message. */
	public static success(message: string): void {
		ToastNotification.NOTYF_INSTANCE.success(message);
	}

	/** Displays a warning message. */
	public static warning(
		message: string,
		...additionalOptions: Array<Partial<INotyfNotificationOptions>>
	): NotyfNotification {
		return ToastNotification.NOTYF_INSTANCE.open(
			ToastNotification.mergeOptions(
				{
					message,
					type: 'warning'
				},
				additionalOptions
			)
		);
	}

	/**
	 * Merges the given option objects. Properties set in additionalOptions (if defined) will overwrite the default
	 * options.
	 */
	private static mergeOptions(
		defaultOptions: Partial<INotyfNotificationOptions>,
		additionalOptions: Array<Partial<INotyfNotificationOptions>> = []
	): Partial<INotyfNotificationOptions> {
		return Object.assign(defaultOptions, ...additionalOptions);
	}

	/** Displays an error message. */
	public static error(errorOrMessage: string | Error, additionalMessage?: string): void {
		if (errorOrMessage instanceof Error) {
			ToastNotification.NOTYF_INSTANCE.error(
				this.appendAdditionalMessage(errorOrMessage.message, additionalMessage)
			);
		} else {
			ToastNotification.NOTYF_INSTANCE.error(this.appendAdditionalMessage(errorOrMessage, additionalMessage));
		}
	}

	private static appendAdditionalMessage(message: string, additionalMessage?: string): string {
		if (additionalMessage != null) {
			message += ' ' + additionalMessage;
		}
		return message;
	}

	/**
	 * Displays a service call error as a toast message. Timeouts and connection resets are displayed differently than
	 * regular service call errors to match the default error handler behavior. If the given error is not a service call
	 * error it is rethrown. We use this to avoid single request failures to crash the whole page state and therefore
	 * loose all input whenever the connection becomes unstable see (TS-22737).
	 *
	 * @param error An arbitrary error object from a rejected promise.
	 */
	public static showIfServiceError(error: unknown): void {
		ToastNotification.showIfServiceErrorWithAdditionalMessage(error);
	}

	/**
	 * Displays a service call error as a toast message. Timeouts and connection resets are displayed differently than
	 * regular service call errors to match the default error handler behavior. If the given error is not a service call
	 * error it is rethrown. We use this to avoid single request failures to crash the whole page state and therefore
	 * loose all input whenever the connection becomes unstable see (TS-22737).
	 *
	 * @param error An arbitrary error object from a rejected promise.
	 * @param additionalMessage An additional message to append to the error message.
	 */
	public static showIfServiceErrorWithAdditionalMessage(error: unknown, additionalMessage?: string): void {
		if (!(error instanceof ServiceCallError)) {
			throw error;
		}
		if (error.statusCode === 0) {
			// Status 0 indicates that Teamscale server may be down for some unknown reason.
			// We do nothing here, so view content remain shown while
			// the view tells the user about this connection issue. Useful for auto-refreshing pages.
			ToastNotification.showUnreachableServerWarning();
			return;
		}
		ToastNotification.error(error, additionalMessage);
	}

	/** Displays an info toast message. */
	public static info(message: string, ...additionalOptions: Array<Partial<INotyfNotificationOptions>>): void {
		ToastNotification.NOTYF_INSTANCE.open(
			ToastNotification.mergeOptions(
				{
					type: 'info',
					message
				},
				additionalOptions
			)
		);
	}

	/** Removes all open toasters immediately. No animation is displayed. */
	public static clearAll(): void {
		ToastNotification.NOTYF_INSTANCE.dismissAll();
	}

	/** Displays a warning to the user indicating that Teamscale server is unreachable. */
	public static showUnreachableServerWarning(): void {
		const notAlreadyShowing =
			!ToastNotification.activeServerNotReachableNotification ||
			ToastNotification.NOTYF_INSTANCE.notifications.indexOf(
				ToastNotification.activeServerNotReachableNotification
			) === -1;
		if (notAlreadyShowing) {
			ToastNotification.activeServerNotReachableNotification = ToastNotification.warning(
				'Teamscale server is unreachable. Maybe network connection is down?',
				{
					duration: 6000,
					dismissible: true
				}
			);
		}
	}

	private static createNotyfInstance(): Notyf {
		return new Notyf({
			position: {
				x: 'right',
				y: 'bottom'
			},
			types: [
				{
					type: 'warning',
					background: 'white',
					className: 'notyf-warning',
					duration: AUTO_HIDE_AFTER_MSECS,
					dismissible: true,
					icon: {
						className: 'exclamation circle icon',
						tagName: 'i'
					}
				},
				{
					type: 'info',
					background: 'white',
					className: 'notyf-info',
					duration: AUTO_HIDE_AFTER_MSECS,
					dismissible: true,
					icon: {
						className: 'info circle icon',
						tagName: 'i'
					}
				},
				{
					type: 'error',
					background: 'white',
					className: 'notyf-error',
					duration: AUTO_HIDE_AFTER_MSECS,
					dismissible: true
				},
				{
					type: 'success',
					className: 'notyf-success',
					background: 'white',
					duration: AUTO_HIDE_AFTER_MSECS / 2.0
				}
			]
		});
	}
}
