import { ServiceCallError } from 'api/ServiceCallError';
import type { JSX, ReactNode } from 'react';
import React, { useContext, useState } from 'react';
import { Icon, Message } from 'semantic-ui-react';
import { ArrayUtils } from 'ts/commons/ArrayUtils';
import { StringUtils } from 'ts/commons/StringUtils';
import type { ConnectorValidationErrorResponseEntity } from 'typedefs/ConnectorValidationErrorResponseEntity';
import styles from './ValidationContainer.module.less';

/** Container to display all the errors, warnings or info messages. */
export function ValidatorContainer(): JSX.Element | null {
	const { errors, warnings } = useValidationContext();
	return (
		<div>
			{!ArrayUtils.isEmptyOrUndefined(errors) ? (
				<Message error className="validation-errors">
					<Icon name="exclamation circle" />
					{errors.map((error, index) => (
						<span className={styles.message} key={'error-' + index}>
							{error}
						</span>
					))}
				</Message>
			) : null}
			{!ArrayUtils.isEmptyOrUndefined(warnings) ? (
				<Message warning className="validation-warnings">
					<Icon name="exclamation triangle" />
					{warnings.map((warning, index) => (
						<span className={styles.message} key={'warning-' + index}>
							{warning}
						</span>
					))}
				</Message>
			) : null}
		</div>
	);
}

/** Context type definition for ValidationContext */
type ExposedValidationContext = {
	addError: (error: string | ServiceCallError) => void;
	addWarning: (warning: string) => void;
	clearValidator: () => void;
	errors: string[];
	warnings: string[];
};

/** Props for ValidationContextProvider. */
type ValidationContextProviderProps = {
	children: ReactNode;
};

/** Context that holds the current details of the displayed comparison. */
const ValidationContext = React.createContext<ExposedValidationContext | undefined>(undefined);

function convertErrorToString(error: string | ServiceCallError): string {
	if (error instanceof ServiceCallError) {
		const validationError = error.fullDescription as ConnectorValidationErrorResponseEntity;
		if (!StringUtils.isEmptyOrWhitespace(error.errorSummary)) {
			return error.errorSummary;
		}
		if (!StringUtils.isEmptyOrWhitespace(validationError.message)) {
			return validationError.message;
		}
		let compoundErrorMessage = '';
		for (const errorMessage of validationError.errorList) {
			compoundErrorMessage += errorMessage + '\n';
		}
		return compoundErrorMessage;
	}
	return error;
}

/** Context provider for an instance comparison */
export function ValidationContextProvider({ children }: ValidationContextProviderProps): JSX.Element {
	const [errors, setErrors] = useState<string[]>([]);
	const [warnings, setWarnings] = useState<string[]>([]);
	const clearValidator = () => {
		setErrors([]);
		setWarnings([]);
	};
	const value: ExposedValidationContext = {
		addError: error => setErrors(errors => [...errors, convertErrorToString(error)]),
		addWarning: warning => setWarnings(warnings => [...warnings, warning]),
		clearValidator,
		errors,
		warnings
	};
	return <ValidationContext.Provider value={value}>{children}</ValidationContext.Provider>;
}

/**
 * Returns the details of the current comparison to be displayed. Must only be used in components nested within a
 * ValidationContextProvider.
 */
export function useValidationContext(): ExposedValidationContext {
	const context = useContext(ValidationContext);
	if (context === undefined) {
		throw new Error('useValidationContext must be used within a ValidationContextProvider');
	}
	return context;
}
