import * as MetricsUtilsTemplate from 'soy/commons/MetricsUtilsTemplate.soy.generated';
import type { SanitizedHtml } from 'ts-closure-library/lib/soy/data';
import { StringBuffer } from 'ts-closure-library/lib/string/stringbuffer';
import { ETgaFigure } from 'typedefs/ETgaFigure';
import { ColorUtils } from './../ColorUtils';
import { UIUtils } from './../UIUtils';
import { MetricFormatterBase } from './MetricFormatterBase';

export type TgaFormatterOptions = { figure?: string };
export type ColorCountObject = Record<string, number>;

/** A formatter for test gap 'assessments', which come as (Java) counter sets. */
export class TestGapAssessmentFormatter extends MetricFormatterBase<TgaFormatterOptions, ColorCountObject> {
	/**
	 * @param options - The following options are supported by this formatter:
	 *
	 *   - {string} figure - The name of an {@link ETgaFigure}.
	 */
	public constructor(options: TgaFormatterOptions) {
		super(options);
	}

	/**
	 * @returns The overall sum and the sort key
	 * @static
	 */
	private static getTotalCountAndSortKey(colorToCountMap: ColorCountObject): { sum: number; sortKey: string } {
		const result = { sum: 0, sortKey: '' };
		const colorNames = Object.keys(colorToCountMap);
		colorNames.forEach((colorName, index) => {
			const colorCount = colorToCountMap[colorName]!;
			result.sum += colorCount;
			result.sortKey += colorName + ': ' + colorCount;
			if (index !== colorNames.length - 1) {
				result.sortKey += ', ';
			}
		});
		return result;
	}

	/** Formats the test gap churn bar by adding a percentage number to it */
	public formatChurnBar(value: ColorCountObject, enableColorBlindMode?: boolean): SanitizedHtml {
		const modifiedCount =
			this.getCountForColor(value, ColorUtils.TGA_COLOR_NAMES.RED) +
			this.getCountForColor(value, ColorUtils.TGA_COLOR_NAMES.ORANGE);
		let percentage = 0;
		if (modifiedCount !== 0) {
			percentage = 100 * (modifiedCount / TestGapAssessmentFormatter.getTotalCountAndSortKey(value).sum);
		}
		return this.buildHtml(value, percentage.toFixed(0) + ' %', enableColorBlindMode);
	}

	/** Formats the test execution bar by adding a percentage number to it */
	public formatExecutionBar(value: ColorCountObject, enableColorBlindMode?: boolean): SanitizedHtml {
		const percentage =
			100 *
			(this.getCountForColor(value, ColorUtils.TGA_COLOR_NAMES.GREEN) /
				TestGapAssessmentFormatter.getTotalCountAndSortKey(value).sum);
		return this.buildHtml(value, percentage.toFixed(0) + ' %', enableColorBlindMode);
	}

	/** Formats the value of a test gap assessment and appends the percentage as a header */
	public formatTestGapBar(value: ColorCountObject, enableColorBlindMode?: boolean): SanitizedHtml {
		const colorSum =
			TestGapAssessmentFormatter.getTotalCountAndSortKey(value).sum -
			this.getCountForColor(value, ColorUtils.TGA_COLOR_NAMES.GRAY);
		const green = this.getCountForColor(value, ColorUtils.TGA_COLOR_NAMES.GREEN);
		let percentage;
		if (colorSum === 0) {
			percentage = 0;
		} else if (green === 0) {
			percentage = 1;
		} else {
			percentage = 1 - green / colorSum;
		}
		return this.buildHtml(value, (percentage * 100).toFixed(0) + ' %', enableColorBlindMode);
	}

	/** Fills in the html from the template */
	public buildHtml(value: ColorCountObject, percentage?: string, enableColorBlindMode?: boolean): SanitizedHtml {
		const colorSum = TestGapAssessmentFormatter.getTotalCountAndSortKey(value).sum;
		const tooltip = this.formatValueAsText(value);
		return UIUtils.sanitizedHtml(
			MetricsUtilsTemplate.testGapBar({
				valueMap: value,
				totalCount: colorSum,
				sortKey: this.getValueForSorting(value),
				tooltip,
				headerText: percentage,
				enableColorBlindMode
			})
		);
	}

	public override formatValueAsHtml(value: ColorCountObject): SanitizedHtml {
		return this.buildHtml(value);
	}

	/**
	 * Returns a comma-separated string in order green -> orange -> red -> gray for the ETestGapState enumeration
	 *
	 * @param source Mapsa color names to frequencies
	 * @returns Comma-separated string.
	 */
	private getValueForSorting(source: ColorCountObject): string {
		const sortedValue = new StringBuffer(this.getCountForColor(source, ColorUtils.TGA_COLOR_NAMES.GREEN), ', ');
		sortedValue.append(this.getCountForColor(source, ColorUtils.TGA_COLOR_NAMES.ORANGE), ', ');
		sortedValue.append(this.getCountForColor(source, ColorUtils.TGA_COLOR_NAMES.RED), ', ');
		sortedValue.append(this.getCountForColor(source, ColorUtils.TGA_COLOR_NAMES.GRAY));
		return sortedValue.toString();
	}

	/** @param source Maps a color names to their frequency */
	private getCountForColor(source: ColorCountObject, colorName: string): number {
		let valueForColor = 0;
		const upperCasedKey = colorName.toUpperCase();
		if (colorName in source) {
			valueForColor = source[colorName]!;
		} else if (upperCasedKey in source) {
			valueForColor = source[upperCasedKey]!;
		}
		return valueForColor;
	}

	public override formatValueAsText(value: ColorCountObject): string {
		const sumAndSortKey = TestGapAssessmentFormatter.getTotalCountAndSortKey(value);
		let formattedValue = sumAndSortKey.sortKey;
		if (this.options.figure != null) {
			const enumValue = ETgaFigure.values.find(enumValue => enumValue.name === this.options.figure);
			formattedValue = enumValue!.tooltipTemplate
				.replace('{red}', String(value.RED ?? 0))
				.replace('{orange}', String(value.ORANGE || 0))
				.replace('{green}', String(value.GREEN || 0))
				.replace('{gray}', String(value.GRAY || 0));
		}
		return formattedValue;
	}
}
