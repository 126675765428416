import type { JSX } from 'react';
import { Dropdown, Icon } from 'semantic-ui-react';
import { QUERY } from 'ts/api/Query';
import { Avatar } from 'ts/base/components/Avatar';
import { useAccessibleViews, useFilterVisibleInSidebar } from 'ts/base/hooks/UseAccessibleViews';
import { useNavigationHash } from 'ts/base/hooks/UseNavigationHash';
import { useUserInfo } from 'ts/base/hooks/UserInfoHook';
import { TeamscaleLink } from 'ts/base/routing/TeamscaleLink';
import { linkTo } from 'ts/commons/links/LinkTo';
import { UserPerspectiveDescriptor } from 'ts/perspectives/user/UserPerspectiveDescriptor';
import { EAvatarSize } from 'typedefs/EAvatarSize';
import { ETeamscalePerspective } from 'typedefs/ETeamscalePerspective';
import type { User } from 'typedefs/User';
import styles from './PerspectiveSettingsBar.module.less';

/** Shows the account dropdown. */
export function AccountMenu(): JSX.Element {
	const currentUser = useUserInfo().currentUser;
	const userViewDescriptor = new UserPerspectiveDescriptor();
	const accessibleViewDescriptors = useAccessibleViews(userViewDescriptor);
	const visibleUserViews = useFilterVisibleInSidebar(accessibleViewDescriptors);
	const hash = useNavigationHash();
	return (
		<Dropdown
			key={hash.getPerspective() + '/' + hash.getViewName()}
			simple
			tabIndex={0}
			icon={false}
			direction="left"
			data-testid="account-dropdown"
			className={styles.accountDropdown}
			openOnFocus={false}
			trigger={
				<Avatar
					user={currentUser}
					avatarSize={EAvatarSize.TINY}
					id="account-popup-button"
					username={currentUser.username}
					ui={false}
				/>
			}
			title={userDisplayName(currentUser)}
		>
			<Dropdown.Menu>
				{visibleUserViews.map(view => (
					<Dropdown.Item
						as={TeamscaleLink}
						key={view.name}
						className="left"
						text={view.name}
						to={linkTo(ETeamscalePerspective.USER, view)}
					/>
				))}
				<Dropdown.Divider />
				<Dropdown.Item
					as="a"
					className="left"
					icon={<Icon color="red" name="sign out alternate" />}
					text="Logout"
					href={QUERY.logout().url}
				/>
			</Dropdown.Menu>
		</Dropdown>
	);
}

/**
 * Provides a display string for the given user, taking into account that both first and last name are optional. If
 * neither is defined, returns the technical username.
 */
function userDisplayName(user: User): string {
	if (user.firstName !== '' && user.lastName !== '') {
		return `${user.firstName} ${user.lastName} (${user.username})`;
	} else if (user.firstName !== '') {
		return `${user.firstName} (${user.username})`;
	} else if (user.lastName !== '') {
		return `${user.lastName} (${user.username})`;
	} else {
		return user.username;
	}
}
