import { createTheme, type MantineThemeOverride, Select } from '@mantine/core';
import { Icon } from 'semantic-ui-react';

export const MANTINE_THEME: MantineThemeOverride = createTheme({
	components: {
		Select: Select.extend({
			defaultProps: {
				rightSectionPointerEvents: 'none',
				rightSection: <Icon name="dropdown" className="pb-[22px]" />,
				size: 'md',
				allowDeselect: false
			}
		})
	}
});
