import { type JSX } from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import { useTeamscaleInfo } from 'ts/base/hooks/TeamscaleInfoHook';
import { useHubSpotChat } from 'ts/base/hooks/UseHubSpotChat';
import { useUserInfo } from 'ts/base/hooks/UserInfoHook';
import styles from 'ts/base/perspective/topbar/PerspectiveSettingsBar.module.less';

/** Shows the button for launching the support chat. */
export function SupportChat(): JSX.Element | null {
	const portalId = useTeamscaleInfo().hubspotChatPortalId;
	const currentUser = useUserInfo().currentUser;
	const chat = useHubSpotChat({ portalId, userEmail: currentUser.emailAddress });

	if (!chat) {
		return null;
	}

	return (
		<div className={styles.chatButton} onClick={chat.show}>
			<Popup
				trigger={<Icon name="comment alternate outline" size="large" />}
				content="Ask questions about technical issues with Teamscale and about software intelligence and quality"
				position="bottom left"
			/>
		</div>
	);
}
