import { type JSX, Suspense, useLayoutEffect } from 'react';
import { Await, parsePath, useAsyncError, useLoaderData, useNavigate, useRevalidator } from 'react-router-dom';
import { PerspectiveLoadingIndicator } from 'ts/base/components/PerspectiveLoadingIndicator';
import { useKeyboardShortcutRegistry } from 'ts/base/hooks/UseKeyboardShortcut';
import { useNavigationHash } from 'ts/base/hooks/UseNavigationHash';
import { PerspectiveSettingsBar } from 'ts/base/perspective/topbar/PerspectiveSettingsBar';
import type { TeamscaleViewLoaderData, TeamscaleViewLoaderDataWithRedirect } from 'ts/base/TeamscaleViewRoot.loader';
import { NavigateToIssueDialog } from 'ts/commons/dialog/NavigateToIssueDialog';
import { ErrorFallback } from 'ts/commons/ErrorFallback';
import { StringUtils } from 'ts/commons/StringUtils';

/**
 * Handles drawing the skeleton of a view including the perspective settings bar. The view itself is also rendered here
 * once loading finished.
 */
export function TeamscaleViewRoot(): JSX.Element | null {
	const loaderData = useLoaderData() as TeamscaleViewLoaderDataWithRedirect;
	const navigate = useNavigate();
	useLayoutEffect(() => {
		// This is a workaround for https://github.com/remix-run/react-router/pull/11100 and should be refactored once this is fixed
		if ('redirect' in loaderData) {
			navigate(parsePath(StringUtils.ensureStartsWith(loaderData.redirect, '/')), { replace: true });
		}
	}, [loaderData, navigate]);
	if ('redirect' in loaderData) {
		return null;
	}

	return <TeamscaleViewRootNoRedirect {...loaderData} />;
}

function TeamscaleViewRootNoRedirect(loaderData: TeamscaleViewLoaderData): JSX.Element | null {
	useKeyboardShortcutRegistry();
	return (
		<>
			<PerspectiveSettingsBar
				viewDescriptor={loaderData.viewDescriptor}
				existingProjectIds={loaderData.existingProjectIds}
			/>
			<Suspense fallback={<PerspectiveLoadingIndicator />}>
				<Await key={loaderData.loadingTimestamp} resolve={loaderData.view} errorElement={<ViewLoadingError />}>
					{resolvedView => resolvedView}
				</Await>
			</Suspense>
			<NavigateToIssueDialog key={useNavigationHash().toString()} />
		</>
	);
}

function ViewLoadingError() {
	const error = useAsyncError();
	const revalidator = useRevalidator();
	return <ErrorFallback error={error} resetErrorBoundary={revalidator.revalidate} />;
}
