import { linkTo } from 'ts/commons/links/LinkTo';
import { TimeUtils } from 'ts/commons/time/TimeUtils';
import type { TypedTimeSpan } from 'ts/commons/time/TypedPointInTime';
import { ETestGapsPerspectiveView } from 'ts/perspectives/tests/ETestGapsPerspectiveView';
import { ETeamscalePerspective } from 'typedefs/ETeamscalePerspective';

/** Returns a URL for the TGA issue overview. */
export function issueTgaOverview(project: string, queryName?: string) {
	return linkTo(ETeamscalePerspective.TEST_GAPS, ETestGapsPerspectiveView.ISSUE_TEST_GAP, project, {
		'issue-query': queryName
	});
}

/** Returns a URL for the TGA unlinked changes view. */
export function unlinkedChangesTga(project: string, baseline: TypedTimeSpan) {
	return linkTo(ETeamscalePerspective.TEST_GAPS, ETestGapsPerspectiveView.UNLINKED_CHANGES_TEST_GAP, project, {
		baseline: TimeUtils.toUrlToken(baseline)
	});
}
