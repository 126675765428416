import type { JSX } from 'react';
import type { PlaceholderProps } from 'semantic-ui-react';
import { Placeholder, Segment } from 'semantic-ui-react';

/** Properties for {@link PlaceholderList} */
type PlaceholderListProps = PlaceholderProps & {
	/** Amount of placeholder lines in the list */
	numberOfLines: number;
	/** Whether to render image placeholders */
	useImagePlaceholder?: boolean;
};

/** Creates a list of placeholders. */
export function PlaceholderList({
	numberOfLines,
	useImagePlaceholder = false,
	...placeholderProps
}: PlaceholderListProps): JSX.Element {
	return (
		<Placeholder {...placeholderProps}>
			{Array(numberOfLines)
				.fill(1)
				.map((element, index: number) => (
					<PlaceholderLine key={index} useImagePlaceholder={useImagePlaceholder} />
				))}
		</Placeholder>
	);
}

type PlaceholderTreemapProps = {
	height?: string;
};

/** Creates a placeholder for a treemap, using the full available width */
export function PlaceholderTreemap({ height }: PlaceholderTreemapProps): JSX.Element {
	return (
		<div style={{ width: '100%', height }}>
			<Placeholder style={{ maxWidth: '100%', height }}>
				<Placeholder.Image />
			</Placeholder>
		</div>
	);
}

/** A placeholder that looks similar to a commit entry. */
export function CommitEntryLikePlaceholder(): JSX.Element {
	return (
		<Segment>
			<Placeholder>
				<Placeholder.Header image>
					<Placeholder.Line />
					<Placeholder.Line />
				</Placeholder.Header>
				<Placeholder.Paragraph>
					<Placeholder.Line />
					<Placeholder.Line />
				</Placeholder.Paragraph>
			</Placeholder>
		</Segment>
	);
}

/** Properties for {@link PlaceholderLine} */
type PlaceholderLineProps = {
	useImagePlaceholder: boolean;
};

/** Creates a single placeholder line. */
function PlaceholderLine({ useImagePlaceholder }: PlaceholderLineProps): JSX.Element {
	if (useImagePlaceholder) {
		return <Placeholder.Image />;
	}
	return <Placeholder.Line />;
}
