import clsx from 'clsx';
import { type GlobalError, useFormState } from 'react-hook-form';
import { Message } from 'semantic-ui-react';

/** Shows global (root) React hook form errors in an error message. */
export function GlobalFormErrors({ className }: { className?: string }) {
	const { errors } = useFormState();
	const entries = flatErrorEntries(errors);
	if (entries.length === 0) {
		return null;
	}
	let content;
	if (entries.length === 1) {
		content = String(entries[0]![1].message);
	} else {
		content = (
			<ul>
				{entries.map(([key, error]) => (
					<li key={'error-' + key}>{error.message}</li>
				))}
			</ul>
		);
	}
	return <Message error className={clsx('whitespace-pre-wrap', className)} content={content} />;
}

function flatErrorEntries(errors: unknown): Array<[string, GlobalError]> {
	if (!errors) {
		return [];
	}
	if (typeof errors === 'object' && 'message' in errors) {
		return [['root', errors as GlobalError]];
	} else {
		return Object.entries(errors).flatMap(([keyPrefix, value]) =>
			flatErrorEntries(value).map(([key, value]) => [`${keyPrefix}.${key}`, value] as [string, GlobalError])
		);
	}
}
