import { QUERY } from 'api/Query';
import * as SourceFormatterTemplate from 'soy/perspectives/metrics/SourceFormatterTemplate.soy.generated';
import { SafeHtml } from 'ts-closure-library/lib/html/safehtml';
import type { AdvancedTooltip } from 'ts-closure-library/lib/ui/advancedtooltip';
import { SourceFormatterContext } from 'ts/commons/formatter/SourceFormatterContext';
import { SmartTableSorter } from 'ts/commons/SmartTableSorter';
import { ToastNotification } from 'ts/commons/ToastNotification';
import { tsdom } from 'ts/commons/tsdom';
import { UIUtils } from 'ts/commons/UIUtils';
import type { EIssueReferenceTypeEntry } from 'typedefs/EIssueReferenceType';
import { EIssueReferenceType } from 'typedefs/EIssueReferenceType';
import type { IssueReference } from 'typedefs/IssueReference';
import type { OffsetBasedRegion } from 'typedefs/OffsetBasedRegion';
import type { UserResolvedSpecItem } from 'typedefs/UserResolvedSpecItem';
import type { UserResolvedTeamscaleIssue } from 'typedefs/UserResolvedTeamscaleIssue';

/** A teamscale issue with its type */
type UserResolvedTeamscaleIssueWithType = UserResolvedTeamscaleIssue & { type: EIssueReferenceTypeEntry };

/** The details for a set of {@link IssueReference}s (which can be spec items and/or issues) */
export type IssueReferenceDetails = {
	issueReferenceDetails: Map<string, UserResolvedTeamscaleIssueWithType>;
	specReferenceDetails: Map<string, UserResolvedTeamscaleIssueWithType>;
	referenceDetails: Map<string, UserResolvedTeamscaleIssueWithType>;
};

/** Used to create popups for code comments that contain references to issues and specification items. */
export class CodeReferences {
	/** The created popups for all references. */
	private readonly popups: AdvancedTooltip[] = [];
	private disposed = false;

	/**
	 * Creates an issue detail popup for each comment that contains issue or specification item ids. It first fetches
	 * all issue details, creates an issue table and adds this table to the popup.
	 */
	public async insertReferencePopups(
		issueReferences: IssueReference[],
		issueReferencesByCommentOffset: Record<number, IssueReference[]>,
		project: string,
		branchName?: string,
		fileIndex = 0
	): Promise<void> {
		const { referenceDetails } = await CodeReferences.fetchDetailsForIssueReferences(project, issueReferences);
		this.createReferencePopups(issueReferencesByCommentOffset, referenceDetails, project, branchName, fileIndex);
	}

	/**
	 * Given a list of issue references, fetches details for each of them. Filters out any references that could not be
	 * resolved, so no null-entries are in the results.
	 */
	public static async fetchDetailsForIssueReferences(
		project: string,
		issueReferences: IssueReference[],
		reThrowError = false
	): Promise<IssueReferenceDetails> {
		const [issueReferenceDetails, specReferenceDetails] = await Promise.all([
			CodeReferences.getIssueReferenceDetailsById(
				issueReferences,
				EIssueReferenceType.ISSUE,
				project,
				reThrowError
			),
			CodeReferences.getIssueReferenceDetailsById(
				issueReferences,
				EIssueReferenceType.SPEC_ITEM,
				project,
				reThrowError
			)
		]);

		const referenceDetails = new Map([
			...Array.from(issueReferenceDetails.entries()),
			...Array.from(specReferenceDetails.entries())
		]);
		return {
			issueReferenceDetails,
			specReferenceDetails,
			referenceDetails
		};
	}

	/** Disposes all the created popups. */
	public dispose(): void {
		this.disposed = true;
		this.popups.forEach(popup => popup.dispose());
	}

	/** Create popups for all issue references. */
	private createReferencePopups(
		issueReferencesByCommentOffset: Record<number, IssueReference[]>,
		referenceDetails: Map<string, UserResolvedTeamscaleIssueWithType>,
		project: string,
		branchName?: string,
		fileIndex = 0
	) {
		if (this.disposed) {
			return;
		}
		for (const commentOffset in issueReferencesByCommentOffset) {
			const issueReferences = issueReferencesByCommentOffset[commentOffset]!;

			const issueReferenceIds = new Set<string>(
				issueReferences.map(issueReference => issueReference.id.internalId)
			);
			const issueReferenceDetails: UserResolvedTeamscaleIssueWithType[] = [];
			for (const issueReferenceId of issueReferenceIds) {
				if (referenceDetails.has(issueReferenceId)) {
					issueReferenceDetails.push(referenceDetails.get(issueReferenceId)!);
				}
			}
			const issueReferencesTableElement = UIUtils.renderAsSafeHtml(SourceFormatterTemplate.issueReferencesTable, {
				project,
				issueReferences: issueReferenceDetails,
				branch: branchName
			});
			const commentOffsetInt = parseInt(commentOffset);
			const regions = CodeReferences.getReferenceRegionsInComment(commentOffsetInt, issueReferences);
			for (const region of regions) {
				this.createIssueReferencesPopup(region, commentOffsetInt, issueReferencesTableElement, fileIndex);
			}
		}
	}

	private static async getIssueReferenceDetailsById(
		issueReferences: IssueReference[],
		issueReferenceType: EIssueReferenceType,
		project: string,
		reThrowError = false
	): Promise<Map<string, UserResolvedTeamscaleIssueWithType>> {
		const uniqueIssueReferenceIds = new Set(
			issueReferences
				.filter(issueReference => issueReference.type === issueReferenceType.name)
				.map(issueReference => issueReference.id.internalId)
		);
		let issueReferenceDetails: Array<UserResolvedSpecItem | UserResolvedTeamscaleIssue> = [];
		try {
			if (issueReferenceType === EIssueReferenceType.ISSUE) {
				issueReferenceDetails = (
					await QUERY.getIssuesDetails(project, {
						'issue-ids': Array.from(uniqueIssueReferenceIds)
					}).fetch()
				).filter(issue => issue != null);
			} else if (issueReferenceType === EIssueReferenceType.SPEC_ITEM) {
				issueReferenceDetails = (
					await QUERY.getSpecItemDetails(project, {
						'spec-item-ids': Array.from(uniqueIssueReferenceIds)
					}).fetch()
				).filter(specItem => specItem != null);
			}
		} catch (error) {
			if (reThrowError) {
				throw error;
			}
			ToastNotification.error(`References to ${issueReferenceType.readableName} could not be loaded.`);
		}

		return CodeReferences.getIssueReferenceDetailsMap(issueReferenceDetails, issueReferenceType);
	}

	/** Get a map of the issue reference details. To each map value, the type of the reference is added. */
	private static getIssueReferenceDetailsMap(
		issueReferenceDetails: Array<UserResolvedSpecItem | UserResolvedTeamscaleIssue>,
		issueReferenceType: EIssueReferenceType
	): Map<string, UserResolvedTeamscaleIssueWithType> {
		const issueReferenceDetailsById: Map<string, UserResolvedTeamscaleIssueWithType> = new Map<
			string,
			UserResolvedTeamscaleIssueWithType
		>();
		issueReferenceDetails.forEach(detail => {
			const detailWithType: UserResolvedTeamscaleIssueWithType = {
				...detail,
				type: issueReferenceType.name
			};
			issueReferenceDetailsById.set(detail.issue.id.internalId, detailWithType);
		});

		return issueReferenceDetailsById;
	}

	/**
	 * Returns all offset regions of the given issue references in the given comment offset.
	 *
	 * @param commentOffset The offset of the comment token
	 * @param issueReferences The issue references
	 * @returns Offset regions of the given issue references in the given comment offset.
	 */
	private static getReferenceRegionsInComment(
		commentOffset: number,
		issueReferences: IssueReference[]
	): OffsetBasedRegion[] {
		const regions: OffsetBasedRegion[] = [];
		for (const issueReference of issueReferences) {
			const commentOffsetsWithRegions = issueReference.commentOffsetsWithRegions;
			for (let i = 0; i < commentOffsetsWithRegions.size; i++) {
				if (commentOffsetsWithRegions.firstElements[i] === commentOffset) {
					regions.push(commentOffsetsWithRegions.secondElements[i]!);
				}
			}
		}
		return regions;
	}

	/**
	 * Creates an issue reference popup that appears when hovering over a given offset region in a comment
	 *
	 * @param region The region of the issue reference
	 * @param commentOffsetInt The offset of the comment
	 * @param issueReferencesTableElement The issue reference table element containing all issue references in the line
	 * @param fileIndex The index of the file to attach the popup to. In code comparison, indices are 0 (left) and 1
	 *   (right)
	 */
	private createIssueReferencesPopup(
		region: OffsetBasedRegion,
		commentOffsetInt: number,
		issueReferencesTableElement: SafeHtml,
		fileIndex = 0
	): void {
		const issueReferenceTokenOffset = commentOffsetInt + region.start;
		const containerElementId = SourceFormatterContext.makeIdForFileAndOffset(fileIndex, issueReferenceTokenOffset);
		const containerElement = document.getElementById(containerElementId);
		if (containerElement == null) {
			return;
		}
		const scrollContainerId = 'issue-reference-table-sorter-' + containerElementId;
		const scrollContainer = SafeHtml.create(
			'div',
			{ class: 'scroll vertically', id: scrollContainerId },
			issueReferencesTableElement
		);
		containerElement.setAttribute('data-testid', 'issue-reference-' + containerElement.textContent);
		const popup = UIUtils.createPopup(containerElement, scrollContainer, 'issue-references-popup');
		this.popups.push(popup);
		SmartTableSorter.hookTableSorting(tsdom.getElementById(scrollContainerId));
	}
}
